import store from "@/store"

export class KeepaGraph {

    constructor(keepa, currency = "$") {
        this.currencyCode = currency
        this.placeholder = $("#api-flot-placeholder")
        this.placeholder2 = $("#api-flot-placeholder2")

        this.data = keepa

        $("#api-graph-range-90").on("click", () => {
            this.keepaGraphScaleUpdate(90)
            this.select(90)
            $("#api-graph-range-90").addClass("a-color-price")
        })
        $("#api-graph-range-30").on("click", () => {
            this.keepaGraphScaleUpdate(30)
            this.select(30)
            $("#api-graph-range-30").addClass("a-color-price")
        })
        $("#api-graph-range-14").on("click", () => {
            this.keepaGraphScaleUpdate(14)
            this.select(14)
            $("#api-graph-range-14").addClass("a-color-price")
        })
        $("#api-graph-range-7").on("click", () => {
            this.keepaGraphScaleUpdate(7)
            this.select(7)
            $("#api-graph-range-7").addClass("a-color-price")
        })
        $("#api-legend-bsr").hover(
            () => {
                // on hove
                this.all_data.forEach(element => {
                    if (element.label == "BSR") {
                        element.lines.lineWidth = 2
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            },
            () => {
                // on leave
                this.all_data.forEach(element => {
                    if (element.label == "BSR") {
                        element.lines.lineWidth = 1
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            }
        )
        $("#api-legend-amz").hover(
            () => {
                // on hove
                this.all_data.forEach(element => {
                    if (element.label == "AMZ") {
                        element.lines.lineWidth = 2
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            },
            () => {
                // on leave
                this.all_data.forEach(element => {
                    if (element.label == "AMZ") {
                        element.lines.lineWidth = 1
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            }
        )
        $("#api-legend-offers").hover(
            () => {
                // on hove
                this.data2.forEach(element => {
                    if (element.label == "Offers") {
                        element.lines.lineWidth = 2
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            },
            () => {
                // on leave
                this.data2.forEach(element => {
                    if (element.label == "Offers") {
                        element.lines.lineWidth = 1
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            }
        )
        $("#api-legend-bb").hover(
            () => {
                // on hove
                this.all_data.forEach(element => {
                    if (element.label == "BuyBox") {
                        element.points.radius = 3
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            },
            () => {
                // on leave
                this.all_data.forEach(element => {
                    if (element.label == "BuyBox") {
                        element.points.radius = 2
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            }
        )
        $("#api-legend-fba").hover(
            () => {
                // on hove
                this.all_data.forEach(element => {
                    if (element.label == "FBA") {
                        element.points.radius = 3
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            },
            () => {
                // on leave
                this.all_data.forEach(element => {
                    if (element.label == "FBA") {
                        element.points.radius = 2
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            }
        )
        $("#api-legend-fbm").hover(
            () => {
                // on hove
                this.all_data.forEach(element => {
                    if (element.label == "FBM") {
                        element.points.radius = 3
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            },
            () => {
                // on leave
                this.all_data.forEach(element => {
                    if (element.label == "FBM") {
                        element.points.radius = 2
                    }
                })
                this.selectedAxes = {
                    xaxis: {
                        from: this.plot.getXAxes()[0].min,
                        to: this.plot.getXAxes()[0].max,
                    },
                }
                this.draw()
                this.plot.setSelection(this.selectedAxes)
            }
        )
        $(this.placeholder).on("plotselected", (event, ranges) => {
          $(".api-graph-range-link").removeClass("a-color-price")
            this.selected(ranges)
        })
        $(this.placeholder2).on("plotselected", (event, ranges) => {
          $(".api-graph-range-link").removeClass("a-color-price")
            this.selected(ranges)
        })
        $(this.placeholder).on("plothover", (event, pos, item) => {
            this.showTooltip(pos, item)
        })
        $(this.placeholder2).on("plothover", (event, pos, item) => {
            this.showTooltip(pos, item)
        })
  
        this.bsr = []
        if(this.data[3]) this.bsr = this.data[3]
        this.amz = []
        if(this.data[0]) this.amz = this.data[0]
        this.fbm = []
        if(this.data[7]) this.fbm = this.data[7]
        this.fba = []
        if(this.data[10]) this.fba = this.data[10]
        this.buyBox = []
        if(this.data[18]) this.buyBox = this.data[18]
        this.offers = []
        if(this.data[11]) this.offers = this.data[11]

        this.mergedArr = [...this.amz, ...this.data[1] ?? [], ...this.fba, ...this.fbm, ...this.buyBox]
  
        this.all_data = [
            {
                label: "Offers",
                color: "blue",
                data: this.offers,
                lines: { show: false },
            },
            {
                label: "BSR",
                yaxis: 1,
                color: "green",
                lines: {
                    lineWidth: 1,
                },
                clickable: true,
                data: this.bsr,
            },
            {
                label: "AMZ",
                yaxis: 2,
                color: "orange",
                lines: {
                    lineWidth: 1,
                    fill: true,
                },
                data: this.amz,
            },
            {
                label: "FBM",
                yaxis: 2,
                color: "#039BE5",
                points: {
                    show: true,
                    radius: 2,
                },
                data: this.fbm,
            },
            {
                label: "FBA",
                yaxis: 2,
                color: "#ff5722",
                points: {
                    show: true,
                    radius: 2,
                },
                data: this.fba,
            },
            {
                label: "BuyBox",
                yaxis: 2,
                color: "#ff004b",
                lines: {
                    show: false,
                },
                points: {
                    show: true,
                    radius: 2,
                    symbol: "diamond",
                },
                data: this.buyBox,
            },
        ]
        this.data2 = [
            {
                label: "Offers",
                yaxis: 1,
                color: "#88d",
                lines: {
                    lineWidth: 1,
                },
                clickable: true,
                data: this.offers,
            }
        ]
        let legendContainer = document.getElementById("api-legend-placeholder")
        let legendContainer2 = document.getElementById("api-legend-placeholder2")
        this.plot_conf = {
            legend: {
                show: false,
                noColumns: 5,
                container: legendContainer,
                // position: "sw",
                labelFormatter: function(label, series) {
                    if (label == "Offers") return ""
                    return label
                },
            },
            grid: {
                clickable: true,
                hoverable: true,
            },
            series: {
                shadowSize: 0,
                lines: {
                    lineWidth: 1,
                    steps: true,
                },
            },
            yaxes: [
                {
                    color: '#565959',
                    min: 0,
                    max: this.getMaxDataInRange(this.bsr) * 1.1,
                    autoScale: "exact",
                    position: "right",
                    labelWidth: 50,
                    reserveSpace: true,
                    tickFormatter: (val, axis) => {
                        return "#" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    },
                    font: {
                        size: 10,
                    },
                },
                {
                    color: '#565959',
                    min: 0,
                    max: this.getMaxDataInRange(this.mergedArr) * 1.3,
                    autoScale: "exact",
                    position: "left",
                    labelWidth: 30,
                    reserveSpace: true,
                    tickFormatter: (val, axis) => {
                        return this.currencyCode + val
                    },
                    font: {
                        size: 10,
                    },
                },
            ],
            selection: {
                mode: "x",
            },
            xaxis: {
                color: '#565959',
                show: false,
                timeBase: "milliseconds",
                autoScale: false,
                mode: "time",
                // timeformat: "%m/%d/%y",
                font: {
                    size: 10,
                },
            },
        }
        this.conf2 = {
            legend: {
                show: false,
                noColumns: 6,
                // position: "sw",
                container: legendContainer2,
            },
            grid: {
                clickable: true,
                hoverable: true,
            },
            series: {
                shadowSize: 0,
                lines: {
                    lineWidth: 1,
                    steps: true,
                },
            },
            yaxes: [
                {
                    color: '#565959',
                    min: 0,
                    max: this.getMaxDataInRange(this.offers) * 1.3,
                    autoScale: "exact",
                    position: "right",
                    labelWidth: 50,
                    reserveSpace: true,
                    font: {
                        size: 10,
                    },
                }
            ],
            selection: {
                mode: "x",
            },
            xaxis: {
                color: '#565959',
                timeBase: "milliseconds",
                autoScale: "exact",
                mode: "time",
                // timeformat: "%m/%d/%y",
                show: true,
                font: {
                    size: 10,
                },
            },
        }
    }

    keepaGraphScaleUpdate(days) {
        store.commit('product/SET_KEEPA_GRAPH_SCALE', days)
    }

    draw() {
        this.plot = $.plot(this.placeholder, this.all_data, this.plot_conf)
        this.plot.setupGrid()
        this.plot.draw()
        this.plot.clearSelection()

        this.plot2 = $.plot(this.placeholder2, this.data2, this.conf2)
        this.plot2.setupGrid()
        this.plot2.draw()
        this.plot2.clearSelection()
    }
  
    select(window) {
        var d = new Date()
        d.setDate(d.getDate() - window)
        if(window <= 30) {
            var dt = new Date()
            dt.setDate(d.getDate() - 90)
            this.plot.setSelection({
                xaxis: {
                    from: dt.getTime(),
                    to: Date.now(),
                },
            })
            this.plot2.setSelection({
                xaxis: {
                    from: dt.getTime(),
                    to: Date.now(),
                },
            })
        }
        this.plot.setSelection({
            xaxis: {
                from: d.getTime(),
                to: Date.now(),
            },
        })
        this.plot2.setSelection({
            xaxis: {
                from: d.getTime(),
                to: Date.now(),
            },
        })
    }

    unselect() {
        this.plot.setSelection({
            xaxis: {
                from: this.plot.getXAxes()[0].datamin,
                to: this.plot.getXAxes()[0].datamax,
            },
        })
        this.plot2.setSelection({
            xaxis: {
                from: this.plot.getXAxes()[0].datamin,
                to: this.plot.getXAxes()[0].datamax,
            },
        })
    }

    selected(ranges) {
        $.each(this.plot.getXAxes(), function(_, axis) {
            var opts = axis.options
            if (ranges.xaxis.to - ranges.xaxis.from < 86400 * 1000) {
                ranges.xaxis.from = ranges.xaxis.to - 86400 * 1000
            }
            opts.min = ranges.xaxis.from
            opts.max = ranges.xaxis.to
        })
        $.each(this.plot2.getXAxes(), function(_, axis) {
            var opts = axis.options
            if (ranges.xaxis.to - ranges.xaxis.from < 86400 * 1000) {
                ranges.xaxis.from = ranges.xaxis.to - 86400 * 1000
            }
            opts.min = ranges.xaxis.from
            opts.max = ranges.xaxis.to
        })
  
        this.plot.getYAxes()[0].options.max =
            this.getMaxDataInRange(this.bsr, ranges.xaxis.from, ranges.xaxis.to) * 1.1
        this.plot.getYAxes()[1].options.max =
            this.getMaxDataInRange(this.mergedArr, ranges.xaxis.from, ranges.xaxis.to) * 1.3
        this.plot2.getYAxes()[0].options.max =
            this.getMaxDataInRange(this.offers, ranges.xaxis.from, ranges.xaxis.to) * 1.3
  
        $("#api-graph-range-selected").text(
            this.millisecondsToString(ranges.xaxis.from) + " to " + this.millisecondsToString(ranges.xaxis.to)
        )
  
        this.plot.setupGrid()
        this.plot.draw()
        this.plot.clearSelection()

        this.plot2.setupGrid()
        this.plot2.draw()
        this.plot2.clearSelection()
    }

    showTooltip(pos, item) {
        if (!pos.x || !pos.y) {
            return
        }
        if (item) {
            $("#api-graph-hint").show()
            // var hoverSeries = item.series; // what series am I hovering?
            var x = item.datapoint[0],
                y = item.datapoint[1]
            var strTip = this.millisecondsToString(x) + "<hr>"
            // var strTip = x + " / " + y + " for " + item.series.label; // start string with current hover
            // var allSeries = graph.plot.getData();
            $.each(this.all_data, function(i, s) {
                // loop all series
                // if (s == hoverSeries) return; // if the loop series is my hover, just keep going
                $.each(s.data, function(j, p) {
                    if (p[0] == x && p[1] > 0) {
                        // if my hover x == point x add to string
                        strTip += "<li>" + s.label + ": " + Math.round(p[1] * 100) / 100 + "</li>"
                    }
                })
            })
            $("#api-graph-hint")
                .css({
                    top: item.pageY - 100,
                    left: item.pageX - (store.getters['nav/isVerticalNavMini'] ? 60 : 250),
                    position: "absolute",
                })
                .html(strTip)
        } else {
            $("#api-graph-hint").hide()
        }
    }
  
    millisecondsToString(time) {
        let d = new Date(time)
        return d.toDateString().substr(3)
    }

    getMaxDataInRange(arr, startPos = 0, endPos = 0) {
        let max = 8
        if (arr && typeof arr[0] != "undefined") {
            if (startPos == 0) {
                startPos = arr[0][0] || 0
            }
            if (endPos == 0) {
                endPos = arr[arr.length - 1][0]
            }
            arr.forEach(e => {
                if (e[0] >= startPos && e[0] <= endPos) {
                    max = Math.max(max, e[1])
                }
            })
        }
        return max
    }
}