<template>
    <div class="saa-keepa-graph">
        <div
            id="api-graph-div"
            class="saa-container"
        >
            <div style="width: 100%; display: flex; flex-direction: column;">
                <div>
                    <ul id="api-legend-ul">
                        <li id="api-legend-bsr" ref="bsr" class="api-legend-li" style="color: green;">
                            {{ $t("BSR") }}
                        </li>
                        <li id="api-legend-amz" class="api-legend-li" style="color: orange;">
                            {{ $t("AMZ") }}
                        </li>
                        <li id="api-legend-fba" class="api-legend-li" style="color: #ff5722;">
                            {{ $t("FBA") }}
                        </li>
                        <li id="api-legend-fbm" class="api-legend-li" style="color: #039be5;">
                            {{ $t("FBM") }}
                        </li>
                        <li id="api-legend-bb" class="api-legend-li" style="color: #ff004b;">
                            {{ $t("BuyBox") }}
                        </li>
                        <li id="api-legend-offers" class="api-legend-li" style="color: #88d;">
                            {{ $t("Offers") }}
                        </li>
                    </ul>
                </div>
                <div id="api-legend-placeholder" style="margin-left: 30px;"></div>
                <div id="api-flot-placeholder" style="height: 200px; width: 100%;"></div>
                <div id="api-legend-placeholder2" style="margin-left: 30px;"></div>
                <div id="api-flot-placeholder2" style="height: 100px; width: 100%;"></div>
            </div>
            <hr style="margin-bottom: 7px;" />
            <div class="saa-keepagraph-controls">
                <div>
                    <a id="api-graph-range-90" class="api-graph-range-link saa-no-underline">90</a> |
                    <a id="api-graph-range-30" class="api-graph-range-link saa-no-underline">30</a> |
                    <a id="api-graph-range-14" class="api-graph-range-link saa-no-underline">14</a> |
                    <a id="api-graph-range-7" class="api-graph-range-link saa-no-underline">7</a>
                </div>
                <div>
                    <span
                        id="api-graph-range-selected"
                        class="a-size-small a-color-secondary"
                        style="float: right;"
                    ></span>
                </div>
            </div>
        </div>
        <div id="api-graph-hint" class="saa-container saa-graph-hint" style="text-align: left; display: none;"></div>
        <v-img v-if="error" :src="productData.keepa_image || noKeepaImage" class="mr-10"/>
    </div>
</template>

<script>
import { KeepaGraph } from "@/plugins/keepa"
import store from '@/store'
import { onMounted, onUnmounted, computed, ref, watch, getCurrentInstance} from "@vue/composition-api";
import Vue from 'vue'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
    props: ['keepa', 'currency'],
    setup(props) {
        const vm = getCurrentInstance().proxy
        const timer = ref(null)
        const keepaGraphScale = computed(() => store.getters['product/keepaGraphScale'])
        const { menuIsVerticalNavMini } = useAppConfig()
        const error = ref(false)
        onMounted(() => renderKeepaGraph())
        onUnmounted(() => distructKeepaGraph())
        watch(() => menuIsVerticalNavMini.value, () => {
            let eventOver = new MouseEvent('mouseover', {
                'view': window,
                'bubbles': true,
                'cancelable': true
            });
            let eventOut = new MouseEvent('mouseout', {
                'view': window,
                'bubbles': true,
                'cancelable': true
            });          
            if (timer.value) {
                clearTimeout(timer.value)
                timer.value = null
            }
            timer.value = setTimeout(() => {
                if(menuIsVerticalNavMini.value) {
                    vm.$refs.bsr.dispatchEvent(eventOver);
                    vm.$refs.bsr.dispatchEvent(eventOut);
                }else {
                    vm.$refs.bsr.dispatchEvent(eventOver);
                    vm.$refs.bsr.dispatchEvent(eventOut);
                }
            }, 300)
        })

        const renderKeepaGraph = (show = true) => {
            try {
                Vue.prototype.graph = new KeepaGraph(props.keepa, props.currency)
                $("#api-graph-div").show()
                Vue.prototype.graph.draw()
                if(keepaGraphScale.value == null) {
                    Vue.prototype.graph.unselect()
                } else {
                    Vue.prototype.graph.select(keepaGraphScale.value)
                }
            } catch (err) {
                error.value = true
                $("#api-graph-div").hide()
                console.log('Keepa graph error: ', err)
            }
        }

        const distructKeepaGraph = () => {
            try {
                Vue.prototype.graph.plot.shutdown()
                Vue.delete(Vue.prototype.graph)
                $("#api-graph-div").hide()
                error.value = false
            } catch (err) {
                error.value = true
                $("#api-graph-div").hide()
                console.log('Keepa graph error: ', err)
            }
        }

        return {
            error,
            menuIsVerticalNavMini,
            productData: computed(() => store.state.product.product),
            noKeepaImage: require('@/assets/images/app/pic_2.jpg'),
        }
    }
}
</script>
