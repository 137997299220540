<template>
    <v-menu>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="grey"
            >
                <v-icon>{{ icons.mdiExportVariant }}</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
            >
                <v-btn
                    text
                    color="grey"
                    :href="'https://t.me/good_amz_deals/425'"
                    target="_blank"
                >
                    <v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.title }}
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mdiExportVariant,mdiSend} from '@mdi/js'

export default {
    name: 'ShareButton',
    setup() {
        return {
            icons: {
                mdiExportVariant
            },
            items: [
                { title: 'Telegram', icon: mdiSend },
            ],
        }
    }
}
</script>