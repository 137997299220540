<template>
<div>
  <!--<breadcrumbs />-->
  <div class="deals-details-title d-flex align-center justify-space-between">
    <span>{{ $t("Product Details") }}</span>
    <div>
      <!-- <ShareButton /> -->
    </div>
  </div>
  <v-row v-if="!productLoading" class="text-nowrap">
    <v-row class="mr-3 mt-3 ml-3 flex-column">
      <v-card style="width: 100%" class="mb-6">
        <v-card-text>
            <KeepaGraph v-if="productData.keepa_csv" :keepa="productData.keepa_csv" :currency="cCodeToSymbol[productData.currency_code]"/>
            <v-img v-else :src="productData.keepa_image || noKeepaImage" class="deals-data-img"/>
        </v-card-text>
      </v-card>
      <!-- Product Description -->
      <v-card v-if="productData.description" class="mb-6">
        <v-card-text class="py-3">
          <v-list-item-title><b>{{ $t('Description:') }}</b></v-list-item-title>
          <span>{{ productData.description }}</span>
        </v-card-text>
      </v-card>
    </v-row>
    <v-col lg="3" md="3" sm="6" xs="4" class="custom-min-col">
      <!-- Product Data -->
      <v-card>
        <div class="px-4 pb-2">
          <v-list class="deals-list-padding">
            <v-list-item>
              <red-flags
                :hasVariations="!!productData.has_variations"
                :bundle="!!productData.bundle"
                :isFragile="!!productData.is_fragile"
                :isWater="!!productData.is_meltable"
                :isBiohazard="!!productData.is_haz_mat"
                :isPackage="!!productData.is_oversize"
                :isAlert="!!productData.is_alert"
                :isAmazon="!!productData.is_amazon"
                :isAdult="!!productData.is_adult"
                :isSmallAndLight="!!productData.is_small_and_light"
                :restricted="productData.restriction_data"
              />
            </v-list-item>
          </v-list>
          <v-divider class="mb-2 mt-1" />
            <div class="mb-2 d-flex">
              <span><b>{{ $t('Category:') }}</b></span>&nbsp;&nbsp;
              <v-tooltip v-if="productData.category" bottom color="secondary" max-width="300px" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ productData.category }}
                    </v-list-item-title>
                </template>
                <span>{{ productData.category }}</span>
              </v-tooltip>
            </div>
            <div class="mb-2">
              <v-tooltip bottom color="secondary" max-width="250px" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <b>{{ $t('30d avg BSR:') }}</b>
                    </span>
                </template>
                <span>
                  <p class="mb-2"><b>Best Sellers Rank</b> or Sales Rank is a score that Amazon assigns the product product based on historical sales data.</p>
                  <p class="mb-2">Seller Assistant App shows <b>current and 30 day average BSR.</b></p>
                * Average BSR is provided by Keepa.</span>
              </v-tooltip>&nbsp;
              <span>
                <v-chip
                  label
                  class="v-chip-light-bg flex-start"
                  :class="`${productData.bsr_avg30 > 0 ? 'success' : 'error'}--text`"
                  :color="`${productData.bsr_avg30 > 0 ? 'success' : 'error'}`"
                >
                  {{ productData.bsr_avg30 | numberWithSpaces  }}
                </v-chip>
              </span>
            </div>
            <div class="mb-2">
              <v-tooltip bottom color="secondary" max-width="250px" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <b>{{ $t('30d BSR Drops:') }}</b>
                    </span>
                </template>
                <span>
                  <p class="mb-2">A sales rank drop is <b>any move from a higher rank to a lower rank.</b></p>
                  <p class="mb-2">Seller Assistant App shows Drops for the last 30 days.</p>
                * Drops data is provided by Keepa.</span>
              </v-tooltip>&nbsp;
              <span> {{ Number(productData.sales_rank_drops30) }} </span>
            </div>
            <div class="mb-2">
              <v-tooltip v-if="productData.size_type" bottom color="secondary" max-width="250px" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <b>{{ $t('Size:') }}</b>
                    </span>
                </template>
                <span>
                  <b>Product size tier.</b> Product size tiers are measurement categories based on the unit weight, product dimensions, and dimensional weight of a packaged item.
                </span>
              </v-tooltip>&nbsp;
              <span> {{ productData.size_type }} </span>
            </div>
            <div>
              <v-row class="align-content-space-between" style="margin: 0">
                <v-tooltip bottom color="secondary" max-width="300px" open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      label
                      color="rgba(19, 156, 224, 0.3)"
                      text-color="#EC6337"
                      class="deals-chip-offers fbm"
                    >
                      <b>FBM:{{ productData.fbm_offers || 0 }}</b>
                    </v-chip>
                  </template>
                  <span>{{ "FBM quantity" }}</span>
                </v-tooltip>&nbsp;&nbsp;&nbsp;
                <v-tooltip bottom color="secondary" max-width="300px" open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      label
                      color="rgba(236, 99, 55, 0.3)"
                      text-color="#EC6337"
                      class="deals-chip-offers fba"
                    >
                      <b>FBA:{{ productData.fba_offers || 0 }}</b>
                    </v-chip>
                  </template>
                  <span>{{ "FBA quantity" }}</span>
                </v-tooltip>
              </v-row>
            </div>
          <v-divider class="mt-3 mb-2" />
          <div class="mb-2">
              <v-tooltip bottom color="secondary" max-width="300px" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <b>{{ $t('Price:') }}</b>
                    </span>
                </template>
                <span>{{ "Sale Price" }}</span>
              </v-tooltip>&nbsp;
              <span>
                {{ cCodeToSymbol[productData.currency_code] }}{{ Number(productData.buy_box_price) !== 0? Number(productData.buy_box_price) : $t("No BuyBox") }}
              </span>
          </div>
          <div class="mb-2">
              <span v-if="productData.cog">
                <b>{{ $t('Cost of Goods:') }}</b>
              </span>&nbsp;
              <span> {{ cCodeToSymbol[productData.currency_code] }}{{ Number(productData.cog) }} </span>
          </div>
          <div class="mb-2">
              <span v-if="productData.third_pl">
                <b>{{ $t('Third Party Logistics:') }}</b>
              </span>&nbsp;
              <span> {{ cCodeToSymbol[productData.currency_code] }}{{ Number(productData.third_pl) }} </span>
          </div>
          <v-divider class="mt-2 mb-3" />
          <div class="mb-2">
              <v-tooltip v-if="productData.profit_fba" bottom color="secondary" max-width="250px" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <b>{{ $t('Profit:') }}</b>
                    </span>
                </template>
                <span>
                  <p class="mb-2"><b>Profit, $.</b> The metric is calculated by the formula:</p>
                  Profit = Price
                  <ul style="list-style-type: none">
                    <li>- Cost of Goods (COG)</li>
                    <li>- Sales Tax (if any)</li>
                    <li>- Referral Fees</li>
                    <li>- VAT (if any)</li>
                    <li>- FBA Fees(if any)</li>
                    <li>- Monthly storage cost (if any)</li>
                    <li>- logistics costs (3PL)</li>
                  </ul>
                </span>
              </v-tooltip>&nbsp;
              <span>
                <v-chip
                  label
                  class="v-chip-light-bg"
                  :class="`${productData.profit_fba > 0 ? 'success' : 'error'}--text`"
                  :color="`${productData.profit_fba > 0 ? 'success' : 'error'}`"
                >
                  {{ cCodeToSymbol[productData.currency_code] }}{{ Number(productData.profit_fba) }}
                </v-chip>
              </span>
            </div>
          <div class="mb-2">
              <v-tooltip v-if="productData.roi_fba" bottom color="secondary" max-width="250px" open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <b>{{ $t('ROI:') }}</b>
                    </span>
                </template>
                <span>
                  <p class="mb-2"><b>Return on Investment, %.</b> The metric is used to understand the profitability of an investment. ROI is calculated by the formula:</p>
                  ROI = Profit </br>
       &nbsp;&nbsp;&nbsp;&nbsp;/ (Cost of goods (COG) </br>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Sales Tax (if any) </br>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ Logistics costs (3PL)) </br>
       &nbsp;&nbsp;&nbsp;&nbsp;* 100
                </span>
              </v-tooltip>&nbsp;
              <span class="success--text"> {{ Number(productData.roi_fba) }}% </span>
            </div>
          <div class="mb-2">
            <span><b>{{ $t('FBA Fees:') }}</b></span>&nbsp;
            <span>{{ cCodeToSymbol[productData.currency_code] }}{{ Number(productData.fees_total) }}</span>
          </div>
          </div>
      </v-card>
    </v-col>
    <v-col lg="3" md="3" sm="6" xs="4" class="custom-min-col mobile">
      <!-- Product Deal Price -->
      <v-card>
        <div class="py-2">
          <div v-if="$can('any product edit', 'all')">
            <v-row class="px-4">
              <v-col cols="8" class="text-left"><h2>{{ $t('Deal Price') }}</h2></v-col>
              <v-col cols="4" class="text-right"><h2>${{ Number(productData.price) }}</h2></v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row class="px-4">
              <v-col cols="8" class="text-left"><h2>{{ $t('Deal Price') }}</h2></v-col>
              <v-col cols="4" class="text-right"><h2>${{ Number(productData.price) }}</h2></v-col>
            </v-row>
          </div>
          <v-list>
            <v-list-item class="d-flex">
              <v-btn
                block
                outlined
                color="primary"
                @click.stop="addToCart(productData.id)"
                :disabled="productsIdsInCart.indexOf(productData.id) !== -1"
              >
                {{ $t('Add to cart') }}
              </v-btn>
            </v-list-item>
            <v-list-item class="d-flex pt-2">
              <v-btn block color="primary" @click="$router.push({name: 'auth-login', query: {route: 'checkout'}})" v-if="unsigned">{{ $t('Buy Now') }}</v-btn>
              <v-btn block color="primary" @click="buyNow(productData.id)" v-else>{{ $t('Buy Now') }}</v-btn>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
      <!-- Product Details -->
      <v-card>
        <div class="py-2">
          <div class="px-4">
            <div class="d-flex mt-2 mb-3">
              <span><b>{{ $t('Marketplace:') }}</b></span>&nbsp;&nbsp;
              <span><a :href="'https://'+productData.product_url_host" rel="nofollow" target="_blank">{{ productData.product_url_host }}</a></span>
            </div>
            <div class="d-flex my-3">
              <span><b>{{ $t('Store:') }}</b></span>&nbsp;&nbsp;
                <v-list-item-title style="flex: inherit">
                  <router-link class="mr-2" :to="{name: 'store-products', params: {slug: productData.market_slug}}">
                    {{ productData.market }}
                  </router-link>
                </v-list-item-title>
                <rating :id-rating="productData.market_rating" />
            </div>
            <div class="d-flex my-3">
              <span><b>{{ $t('Source:') }}</b></span>&nbsp;&nbsp;
              <v-list-item-title><a :href="'https://'+productData.source_link" rel="nofollow" target="_blank">{{ productData.source_link }}</a></v-list-item-title>
            </div>
            <div class="d-flex my-3">
              <span><b>{{ $t('Status:') }}</b></span>&nbsp;&nbsp;
              <span>
                <v-chip
                    small
                    :color="statusColor[status[productData.status]]"
                    :class="`${statusColor[status[productData.status]]}--text`"
                    class="v-chip-light-bg"
                    style="font-size: 14px"
                  >
                    {{ status[productData.status] }}
                  </v-chip>
              </span>
            </div>
            <div class="d-flex my-3">
              <span><b>{{ $t('Sold Times:') }}</b></span>&nbsp;&nbsp;
              <span>{{ productData.sold_times }}/3</span>
            </div>
            <div class="d-flex my-3">
              <span><b>{{ $t('Сreated:') }}</b></span>&nbsp;&nbsp;
              <span>{{ productData.created_at }}</span>
            </div>
            <div class="d-flex my-3" v-if="productData.status == 2">
              <span><b>{{ $t('Verified:') }}</b></span>&nbsp;&nbsp;
              <span>{{ productData.verified_at }}</span>
            </div>
            <div class="d-flex mt-3 mb-2">
              <span><b>{{ $t('Updated:') }}</b></span>&nbsp;&nbsp;
              <span>{{ productData.updated_at }}</span>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
  <product-details-skeleton v-else></product-details-skeleton>
</div>
</template>

<script>
import store from "@/store";
import {computed, onBeforeMount, watch} from "@vue/composition-api";
import router from "@/router";
import {useRouter} from "@core/utils";
import { numberWithSpaces, cCodeToSymbol, kitCut } from "@/plugins/helpers"
import {buyNowClicked, productAddedToCart} from "@/plugins/tracking/events";
import {logEventAmplitude} from "@/plugins/tracking/amplitude";
import {logEventMixPanel} from "@/plugins/tracking/mixpanel";
import RedFlags from "@/views/market/components/RedFlags";
import ProductDetailsSkeleton from "@/views/market/components/ProductDetailsSkeleton";
import KeepaGraph from "@/views/market/components/KeepaGraph";
import ShareButton from "@/views/market/components/ShareButton";
import Rating from "./components/Rating";
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  name: "ProductDetails",
  components: {
    ShareButton,
    KeepaGraph,
    ProductDetailsSkeleton,
    RedFlags,
    Rating
  },
  filters: {
    numberWithSpaces,
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: `Product #${this.$route.params.id} - Seller Assistant Deals`,
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: this.$route.path,
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.productData.keepa_image_seo ?? this.productData.keepa_image,
        },
      ],
      title: `Product #${this.$route.params.id}`
    }
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const {router,route} = useRouter()
    onBeforeMount(() => store.dispatch('product/getProduct', {id: router.currentRoute.params.id}))
    watch(route, () => store.dispatch('product/getProduct', {id: router.currentRoute.params.id}))
    const productsIdsInCart = computed(() => store.state.cart.cartIds)
    const cutString = (text, limit = menuIsVerticalNavMini.value ? 10 : 10) => kitCut(text, limit)
    const addToCart = (id) => {
      let event = productAddedToCart(id)
      logEventAmplitude(event.name, event.props)
      logEventMixPanel(event.name, event.props)
      return store.dispatch('cart/setNewIdToCart', {id})
    }
    const buyNow = (id) => {
      let eventProductAdded = productAddedToCart(id)
      logEventAmplitude(eventProductAdded.name, eventProductAdded.props)
      logEventMixPanel(eventProductAdded.name, eventProductAdded.props)
      logEventAmplitude(buyNowClicked.name)
      logEventMixPanel(buyNowClicked.name)
      addToCart(id).then(() => router.push({name: 'checkout'}))
    }
    const productData = computed(() => store.getters['product/product'])
    const productLoading = computed(() => store.getters['product/loading'])
    const unsigned = computed(() => route.value.meta.user === 'unsigned')
    const sellCountColor = (count) => {
      switch (count) {
        case 0:
          return ''
        case 1:
          return 'success'
        case 2:
          return 'success'
        default:
          return 'error'
      }
    }
    return {
      menuIsVerticalNavMini,
      cutString,
      cCodeToSymbol: cCodeToSymbol(),
      sellCountColor,
      productData,
      productLoading,
      productsIdsInCart,
      unsigned,
      addToCart,
      buyNow,
      noKeepaImage: require('@/assets/images/app/pic_2.jpg'),
      status: {
        1: 'Unverified',
        2: 'Verified',
      },
      statusColor: {
        /* eslint-disable key-spacing */
        Verified: 'success',
        Unverified: 'warning',
        /* eslint-enable key-spacing */
      },
    }
  }
}
</script>
<style scoped>
.v-list-item {
  min-height: 35px;
}
</style>